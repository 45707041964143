/* eslint-disable sort-keys */

/** The available screen sizes, in ascending order */
export const SCREEN_SIZES = ["xs", "sm", "md", "lg", "xl", "2xl"] as const;
export type ScreenSize = (typeof SCREEN_SIZES)[number]; // "xs" | "sm" | ...

/**
 * The min-widths for each of Tailwind's screens (breakpoints) as plain numbers
 *
 * @see {@link https://tailwindcss.com/docs/responsive-design}
 */
export const SCREEN_WIDTH = {
  xs: 390,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const satisfies Record<ScreenSize, number>;

/**
 * Used by Storybook Stories to provide all available viewport modes based on screen sizes
 *
 * @see {@link https://www.chromatic.com/docs/modes/}
 */
export const SCREEN_MODES: Record<string, { viewport: string }> = Object.keys(
  SCREEN_WIDTH,
).reduce((acc: any, size) => {
  acc[size] = {
    viewport: size,
  };
  return acc;
}, {});
