import { Features, type FeaturesMap } from "@/configs/features";

export const isFeature = (feature: string): feature is keyof FeaturesMap => {
  return feature in Features;
};

export const parseBucket = (bucket: string): string[] => {
  return Array.from(new URLSearchParams(bucket).values());
};

const tryJSONDecode = (json: any) => {
  try {
    return JSON.parse(decodeURIComponent(json));
  } catch (_) {
    return json;
  }
};

const queryStringParse = (queryString: string) => {
  return Object.fromEntries(new URLSearchParams(queryString));
};

/**
 * Evaluate stores the bucket meta data in a `y-bucket-N` header,
 * the `y-bucket-max` header indicates how many bucket layers are present.
 */
const buildBucketsString = (headers: any): string => {
  const headerCount = parseInt(headers.get("y-bucket-max"), 10) || 0;
  let headerString = "";
  for (let i = 1; i <= headerCount; i += 1) {
    headerString += headers.get(`y-bucket-${i}`);
  }
  return headerString;
};

/**
 * Parses a bucket header string into an object of bucket name and metadata.
 * @example 1025333=v=3&b=gemini_article_footer-copy&i=841990&t=Bcookie&d={"article_footer_recirc":"gemini"}&c={"article_footer_recirc":"gemini"}&u=&h=531&x=10388
 * @see https://archives.ouroath.com/twiki/twiki.corp.yahoo.com/view/SDSMain/ExperimentationInstrumentationNodeJS.html
 */
const parseBucketHeader = (
  headerString: string,
): { name: string; meta: any } => {
  const parts = queryStringParse(headerString) as Record<string, string>;
  return {
    meta: tryJSONDecode(parts.d),
    name: parts.b,
  };
};

/**
 * Parses a bucket string into a buckets object and metadata object.
 * Concating the bucket layer meta data into a single object.
 */
const parseBuckets = (bucketsString: string) => {
  const { buckets, metaData } = bucketsString.split(";").reduce(
    (memo: any, bucketString: string) => {
      const res = !!bucketString && parseBucketHeader(bucketString);
      const accum = memo;
      if (res && res.name) {
        accum.buckets[res.name] = res.meta || true;
        if (res.meta === Object(res.meta)) {
          accum.metaData = Object.assign(accum.metaData, res.meta);
        }
      }
      return accum;
    },
    { buckets: {}, metaData: {} },
  );
  return {
    buckets,
    metaData,
  };
};

const FEATURE_PREFIX = "feature.";

/**
 * Filter Evaluate meta data to only return valid features
 */
export const getEvaluateFeatures = (headers: HeadersInit): FeaturesMap => {
  const { metaData } = parseBuckets(buildBucketsString(headers) || "");

  return getFeatures(metaData);
};

const getCsvFeatures = (params: URLSearchParams): FeaturesMap => {
  const csv = params.get("feature");
  if (!csv) {
    return {};
  }

  const features: FeaturesMap = {};

  for (let feature of csv.split(",")) {
    const enabled = feature[0] !== "-";
    feature = enabled ? feature : feature.slice(1);
    if (isFeature(feature)) {
      features[feature] = enabled;
    }
  }

  return features;
};

export const getFeatures = (
  params: URLSearchParams | Record<string, any>,
): FeaturesMap => {
  const features: FeaturesMap = {};

  if (params instanceof URLSearchParams) {
    params = Object.fromEntries(params.entries());
  }

  for (const [key, value] of Object.entries(params)) {
    if (!key.startsWith(FEATURE_PREFIX)) {
      continue;
    }

    const feature = key.replace(FEATURE_PREFIX, "");
    if (!isFeature(feature)) {
      continue;
    }

    features[feature] = value === "1" || value === true;
  }

  return features;
};

/**
 * Get feature flags from the query string
 */
export const getQueryFeatures = (
  searchParams: URLSearchParams,
): FeaturesMap => ({
  // gather features from query string (e.g. ?feature=foo,bar)
  ...getCsvFeatures(searchParams),
});
