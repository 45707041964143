export const isPageNumber = (part: string) =>
  !isNaN(Number(part)) && Number(part) > 0;

export const getSlugParts = (params: { parts: string[] }) => {
  if (!params || !params.parts) {
    return undefined;
  }
  const pageIndex = params.parts.length - 1; // Assuming the last part is the page number

  return isPageNumber(params.parts[pageIndex])
    ? params.parts.slice(0, pageIndex)
    : params.parts;
};

export enum PAGES {
  HOME = "home",
  CATEGORY = "category",
  SUBCATEGORY = "subcategory",
  ARTICLE = "article",
  MERCHANT = "store",
  LOCAL = "local",
  NOT_FOUND = "404",
}

export const categoryPageType = (params: { parts: string[] }) => {
  const [, subcategory] = getSlugParts(params) || [];

  if (subcategory) {
    return PAGES.SUBCATEGORY;
  }

  return PAGES.CATEGORY;
};

export const getCategoryPath = (
  category: string,
  subCategory: string | undefined,
  pageNumber?: number,
) => {
  return [category, subCategory, pageNumber]
    .filter(Boolean)
    .join("/")
    .toLowerCase();
};

export function getPathWithoutPage(pathname: string) {
  const pageRegex = /\/\d+\/$/;
  if (pageRegex.test(pathname)) {
    return pathname.replace(/\/\d+\/$/, "/");
  }

  return pathname;
}

export enum NumPaginatedItemsByModule {
  GRID = 15,
  LIST = 11,
}
