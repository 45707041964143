"use client";

import { useEffect, useState } from "react";
import { SCRIPT_ID } from "../lib/constants";

export const useEsiVars = <T>(initialState: T) => {
  const [vars, setVars] = useState<T>(initialState);

  useEffect(() => {
    const el = document.getElementById(SCRIPT_ID);
    if (!el) {
      return;
    }

    try {
      setVars(JSON.parse(el.innerHTML));
    } catch (_) {
      // do nothing
    }
  }, []);

  return vars;
};
