"use client";

import { useEffect, useState } from "react";

export const useMatchesMediaQuery = (mediaQuery: string): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    if (!mediaQueryList) return;
    const onChange = () => {
      setMatches(mediaQueryList.matches);
    };

    if (mediaQueryList?.addEventListener) {
      mediaQueryList.addEventListener("change", onChange);
    } else {
      mediaQueryList?.addListener(onChange); // safari 13 https://stackoverflow.com/questions/62693995/addeventlistener-is-not-a-function-for-matchmedia-in-safari-browser
    }
    onChange();

    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener("change", onChange);
      } else if (mediaQueryList.removeListener) {
        mediaQueryList.removeListener(onChange);
      }
    };
  }, [mediaQuery]);

  return matches;
};
