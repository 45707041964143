export const BASE_URL = (site: string) => `https://www.${site}.yahoo.com`;

interface SiteParams {
  url: string;
  site: string;
}

interface BaseUrlOverrideParams {
  url: string;
  baseUrlOverride: string;
}

/**
 * Utility function to get the URL object given an absolute or relative URL.
 *
 * @param {SiteParams | BaseUrlOverrideParams} params The parameters to get the URL object
 * @param {string} params.url The URL to get the URL object for
 * @param {string} params.site The site name to use for the base URL - required if baseUrlOverride is not provided
 * @param {string} params.baseUrlOverride The base URL to use instead of the site - required if site is not provided
 *
 * @return {URL | null} The URL object or null if the URL is invalid
 */
export const getUrlObject = (params: SiteParams | BaseUrlOverrideParams) => {
  try {
    // Use the base URL override if provided, otherwise use the site
    if ("baseUrlOverride" in params) {
      // Remove the leading '/' if it exists
      if (params.url.startsWith("/")) {
        params.url = params.url.substring(1);
      }
      return new URL(params.url, params.baseUrlOverride);
    } else {
      return new URL(params.url, BASE_URL(params.site));
    }
  } catch {
    return null;
  }
};

/**
 * Utility function to replace non-alphanumeric characters with a "-"
 * @param {string} url path name
 * @return url path name after non-alphanumeric characters being replaced
 */
export const replaceNonAlphanumericUrlPath = (urlPathName: string) => {
  if (typeof urlPathName === "string") {
    return urlPathName.replace(/&/g, "and").replace(/\W/g, "-");
  }
  return "";
};
