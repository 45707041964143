import { mapValues } from "@yahoo-commerce/util";
import { type FC } from "react";
import { SCRIPT_ID } from "../lib/constants";

const mapFn = (name: string) => `$(HTTP_HEADER{${name}})`;

interface Props {
  vars: Record<string, string>;
}

/**
 * This component creates the ESI variables templates which are processed
 * by the edge in order to stamp dynamic data into an otherwise cached page.
 *
 * Each value of vars is a request header which must be set by the edge
 * so that its value can be included in the response. The values can be accessed
 * on the client through the useEsiVars hook, dereferenced by key.
 */
export const EsiVars: FC<Props> = ({ vars }) => {
  const mapped = mapValues(vars, mapFn);
  const __html = `<esi:vars>${JSON.stringify(mapped)}</esi:vars>`;

  return (
    <script
      dangerouslySetInnerHTML={{ __html }}
      id={SCRIPT_ID}
      suppressHydrationWarning
      type="application/json"
    />
  );
};
