"use client";

import { getPerfVitalsUrl } from "@vzmi/perf-vitals";
import { type Config as RapidConfig } from "@vzmi/types-rapid";
import Script from "next/script";
import type {} from "../types/Window";

import { type FC } from "react";

type Props = {
  /** Rapid Config */
  config: RapidConfig;
  /** CDN URL for Rapid */
  rapidUrl?: string;
};

type BeaconClick = {
  elm?: string;
  sec: string;
  slk: string;
  _p?: number;
  options?: {
    dwell?: "start" | "stop";
    pp?: Record<string, any>;
  };
  outcome?: string;
  pos?: number;
};

type BeaconLinkView = {
  elm: string;
  itc?: string | number;
  sec: string;
  slk: string;
};

export function beaconClick(
  { sec, slk, _p = 0, options, outcome, ...restProps }: BeaconClick,
  callback?: () => void,
) {
  const { rapidInstance } = window;
  if (!rapidInstance) {
    return;
  }
  rapidInstance.beaconClick(
    sec,
    slk,
    _p,
    restProps,
    outcome,
    callback,
    options,
  );
}

export function beaconPageview(keys?: Record<string, string | number>) {
  const { rapidInstance } = window;
  if (!rapidInstance) {
    return;
  }
  rapidInstance.beaconPageview(keys);
}

export function beaconLinkViews({ elm, itc, sec, slk }: BeaconLinkView) {
  const { rapidInstance } = window;
  if (!rapidInstance) {
    return;
  }

  const links = [{ _links: [{ elm, itc, slk }], sec }];
  rapidInstance.beaconLinkViews(links);
}

export function beaconEvent(
  name: string,
  pageParams?: any,
  outcomeName?: string,
) {
  const { rapidInstance } = window;
  if (!rapidInstance) {
    return;
  }

  rapidInstance.beaconEvent(name, pageParams, outcomeName);
}

export function reInit(config: RapidConfig) {
  const { rapidInstance } = window;
  if (!rapidInstance) {
    return;
  }

  rapidInstance.reInit(config);
}

export function addContentProgression(module: string) {
  // wait for rapidInstance to be available
  const checkRapid = setInterval(function () {
    const { rapidInstance } = window;
    if (module && rapidInstance) {
      clearInterval(checkRapid);
      rapidInstance.addModuleProgression(module);
    }
  }, 100);
}

export function endContentProgression(module: string) {
  const { rapidInstance } = window;
  if (module && rapidInstance) {
    rapidInstance.endModuleProgression(module);
  }
}

function handleRapidClicks(e: MouseEvent) {
  const target = e.target as HTMLElement;
  // only targets within the boundary will be tracked, otherwise use Rapid's auto tracking
  const i13nBoundary = target.closest("[data-i13n-boundary]");
  if (!i13nBoundary) {
    return;
  }
  const isRapidDisabled = target.classList.contains("rapid-noclick-resp");
  const dataYlk = target.getAttribute("data-ylk");

  if (isRapidDisabled && dataYlk) {
    const ylkParts = dataYlk.split(";");
    const beaconObject: any = {};

    for (const pair of ylkParts) {
      const [key, value] = pair.split(":");
      beaconObject[key] = value;
    }

    beaconClick(beaconObject);
  }
}

export const Rapid: FC<Props> = ({
  config,
  rapidUrl = "https://s.yimg.com/ss/analytics3.js",
}) => {
  return (
    <>
      <Script
        src={rapidUrl}
        onLoad={() => {
          window.rapidInstance = new window.YAHOO.i13n.Rapid(config);

          document.body.addEventListener("click", handleRapidClicks);
        }}
      />
      <Script src={getPerfVitalsUrl()} strategy="afterInteractive" />
    </>
  );
};
