"use client";

import { useEsiVars } from "@yahoo-commerce/edge";
import { type EdgeSideIncludes } from "@/configs/esi";
import { parseBucket } from "@/lib/request/bucket";

export const useBucket = () => {
  const { bucket } = useEsiVars<Pick<EdgeSideIncludes, "bucket">>({
    bucket: "",
  });

  return parseBucket(bucket);
};
