import { beaconError } from "@yahoo-commerce/logger";
import { useEffect } from "react";
import { SCRIPT_ID } from "@yahoo-commerce/edge/lib/constants";

const ALLOWLIST = new Set([
  "https://shopping.yahoo.com",
  "https://local.yahoo.com",
  "https://autos.yahoo.com",
  "https://health.yahoo.com",
]);

/**
 * This hook is used to detect ESI failures and beacon an error if it happens.
 * It will only run on production hosts (ie https://shopping.yahoo.com).
 * This list can be amended in the future to support additional hosts
 * eg https://travel.yahoo.com, https://auto.yahoo.com/, etc.
 */
export const useEsiCanary = () => {
  useEffect(() => {
    if (!ALLOWLIST.has(location.origin)) {
      return;
    }

    const el = document.getElementById(SCRIPT_ID);
    const innerHTML = el?.innerHTML;
    const success = innerHTML?.indexOf("<esi:vars>") === -1;
    if (success) {
      return;
    }

    const error: any = new Error("ESI failure");
    error.innerHTML = innerHTML;
    delete error.stack; // the stack trace is not useful in this case
    beaconError("esi-canary", error);
  }, []);

  return null;
};
