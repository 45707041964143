const COMMERCE_SUBNAV_LIST_IDS: Record<string, string> = {
  autos: "d60e5c00-8b4f-4a10-b1b5-1584d2708344",
  default: "580e9e88-4ebe-464b-9470-8ba8597e245d",
  engadget: "2f646750-366c-4e78-a17c-067e57be634a",
  finance: "",
  health: "35b2f1bc-49d3-47be-9a0b-a02a194f8bf7",
  news: "",
  tech: "2f646750-366c-4e78-a17c-067e57be634a",
  walmart: "35366888-a1b5-4924-94c4-86f1b7cda0d4",
};

const LOCAL_SUBNAV_LIST_IDS: Record<string, string> = {
  movers: "4c26676a-d06f-43ff-9cb7-86686f36ec20",
  painting: "7c09fd0a-df1e-47a9-ad92-6a39534720af",
  plumbing: "7c09fd0a-df1e-47a9-ad92-6a39534720af",
  roofing: "7c09fd0a-df1e-47a9-ad92-6a39534720af",
};

const NON_COMMERCE_SUBNAV_LIST_IDS: Record<string, string> = {
  autos: "d60e5c00-8b4f-4a10-b1b5-1584d2708344",
  default: "",
  engadget: "e82334f3-c941-4231-88c5-ace473ea21f0",
  entertainment: "49186f62-93c7-4b2c-8bc9-fe3c631e45a6",
  health: "35b2f1bc-49d3-47be-9a0b-a02a194f8bf7",
  lifestyle: "a85a71df-7105-4d02-9485-446b852a805b",
  news: "d3a25796-37da-493a-b8f5-4c0d4f58a63a",
  tech: "e82334f3-c941-4231-88c5-ace473ea21f0",
};

type ContentMeta = {
  commerceArticleType?: string;
  canonicalSite?: string;
  contentTags?: {
    normalizedTags?: {
      editorialTags?: string[];
      ymedia?: Record<string, string[]>;
    };
  };
  siteSections?: {
    sectionContentCategoryPath?: string;
  };
};

// Get the subnavList ID based on the canonical site or site section for articles viewed through the US edition.
export const getTopicSubnavListId = (
  lang: string,
  contentMeta: ContentMeta = {},
) => {
  if (lang !== "en-US") return "";

  const { canonicalSite, commerceArticleType, contentTags, siteSections } =
    contentMeta || {};

  if (commerceArticleType) {
    const isWalmartSubnav = (
      contentTags?.normalizedTags?.ymedia?.["walmart-subnav"] || []
    ).includes("on");
    if (isWalmartSubnav) {
      return COMMERCE_SUBNAV_LIST_IDS.walmart;
    }

    if (canonicalSite === "local") {
      const sectionContentCategoryPath =
        siteSections?.sectionContentCategoryPath;
      return (
        (sectionContentCategoryPath &&
          LOCAL_SUBNAV_LIST_IDS[sectionContentCategoryPath]) ||
        ""
      );
    }
  }

  const subnavListIds = commerceArticleType
    ? COMMERCE_SUBNAV_LIST_IDS
    : NON_COMMERCE_SUBNAV_LIST_IDS;

  return canonicalSite && subnavListIds.hasOwnProperty(canonicalSite)
    ? subnavListIds[canonicalSite]
    : subnavListIds.default;
};
