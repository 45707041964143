"use client";

import {
  createContext,
  useState,
  useContext,
  type PropsWithChildren,
  type FC,
  useCallback,
} from "react";

export interface ModulePositionContextType {
  addModule?: (componentName: string) => void;
  getModulePosition?: (componentName: string) => number | undefined;
}

const ModulePositionContext = createContext<ModulePositionContextType>({});

export const ModulePositionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modules, setModules] = useState<string[]>([]);

  const addModule = useCallback((componentName: string) => {
    setModules((prevOrder) => {
      if (prevOrder?.includes(componentName)) {
        return prevOrder;
      }
      return [...prevOrder, componentName];
    });
  }, []);

  const getModulePosition = useCallback(
    (componentName: string) => {
      return modules.length >= 0
        ? modules.indexOf(componentName) + 1
        : undefined;
    },
    [modules],
  );

  return (
    <ModulePositionContext.Provider value={{ addModule, getModulePosition }}>
      {children}
    </ModulePositionContext.Provider>
  );
};

export const useModulePosition = () => useContext(ModulePositionContext);
