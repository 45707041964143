"use client";

import { useEffect } from "react";
import {
  type I13NSec,
  refreshRapid,
  useModulePosition,
} from "@yahoo-commerce/i13n";

export const useRapid = (id: string, sec?: I13NSec) => {
  const { addModule, getModulePosition } = useModulePosition();

  useEffect(() => {
    if (addModule) addModule(id);
  }, [addModule, id]);

  useEffect(() => {
    if (!id) {
      return;
    }
    let timer: number | NodeJS.Timeout;

    const initModuleI13n = (id: I13NSec) => {
      if (window.rapidInstance) {
        refreshRapid(id, sec);
      } else {
        if (timer) {
          clearTimeout(timer);
        }
        // Wait until rapid JS is initialized
        timer = setTimeout(initModuleI13n, 100, id);
      }
    };

    initModuleI13n(id);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [id, sec]);

  return {
    mpos: getModulePosition ? getModulePosition(id) : undefined,
  };
};
