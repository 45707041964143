import { useEsiVars } from "@yahoo-commerce/edge";
import { type EdgeSideIncludes } from "@/configs/esi";
import { type FeaturesMap } from "@/configs/features";
import { getEvaluateFeatures } from "@/lib/request/bucket";

export const useEsiEvaluateFeatures = (bot: boolean): FeaturesMap => {
  const esiVars = useEsiVars<
    Pick<EdgeSideIncludes, "y-bucket-1" | "y-bucket-max">
  >({
    "y-bucket-1": "",
    "y-bucket-max": "1",
  });

  if (bot) {
    return {}; // ignore evaluate features for bots
  }

  const headers = new Headers({
    "y-bucket-1": esiVars["y-bucket-1"],
    "y-bucket-max": esiVars["y-bucket-max"],
  });

  return getEvaluateFeatures(headers);
};
